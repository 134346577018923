<template>
    <div>
        <ul class="tab-navs spmg-bottom-db">
            <li class="tn-item current">
                修改密码
            </li>
        </ul>
        <div class="form-box form-mypsw">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm">
                <el-form-item  prop="oldpw">
                    <el-input type="password" v-model="ruleForm.oldpw"  placeholder="原密码"></el-input>
                </el-form-item>
                <el-form-item  prop="newpw">
                    <el-input type="password" v-model="ruleForm.newpw" placeholder="新密码"></el-input>
                </el-form-item>
                <el-form-item  prop="checknewpw">
                    <el-input type="password" v-model="ruleForm.checknewpw" placeholder="确认密码"></el-input>
                </el-form-item>
            </el-form>
            <div class="btns-box">
                <button class="btn btn-save" type="button" @click="Confirm('ruleForm')">确定修改</button>
            </div>
        </div>   
    </div>
</template> 
<script>
export default {
    data () {
        var validoldpw = (rule,value,callback) =>{
            if(value === ''){
                callback(new Error('请输入原密码'))
            } else {
                callback()
            }
        }
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码'));
            } else if(!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,}$/.test(value)) {
                callback('密码为8-16位字母、数字或符号至少2种组合')
            }else{
                if (this.ruleForm.checknewpw !== '') {
                    this.$refs.ruleForm.validateField('checknewpw');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.newpw) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            imageUrl:'',
            ruleForm:{
                oldpw:'',
                newpw:'',
                checknewpw:''
            },
            rules:{
                oldpw:[
                    { validator: validoldpw, trigger: 'blur' }
                ],
                newpw: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checknewpw: [
                    { validator: validatePass2, trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        Confirm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.token = this.$store.state.token
                    this.axios.changepw(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==0){
                            this.$message.success('修改成功,请重新登录')
                            localStorage.clear(); 
                            // this.$router.push('/'),
                            location.reload();
                            
                        }else{

                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        handleAvatarSuccess(res){
            this.imageUrl = res
        }
    },
    mounted() {

    }
}
</script>
<style scoped>
@import "./../../styles/Page/Downloadlatest.css";
</style>
<style>
.member_cover .avatar-uploader .el-upload {
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.member_cover .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 54px;
    height: 54px;
    line-height: 54px;
    text-align: center;
}
.member_cover .avatar {
    width: 54px;
    height: 54px;
    display: block;
  }
</style>